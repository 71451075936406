// type:input、checkbox、tag、select、textarea、custom_input、
import { CoaType } from "./typeCoaConfig/CoaType";

export const typeData = [
  {
    type: "Powder",
    data: CoaType
  },
  {
    type: "Liquid",
    data: CoaType
  },
  {
    type: "EHXTO",
    data: CoaType
  },
  {
    type: "SHXTO",
    data: CoaType
  },

]

export const typeCNData = [
  {
    type: "TY-EHXTO",
    data: CoaType
  },
  {
    type: "TY-L",
    data: CoaType
  },
  {
    type: "TY-P",
    data: CoaType
  },
  {
    type: "TY-SHXTO",
    data: CoaType
  }
]

// 数据枚举
export const DataKeys = {
  BASE_INFO: 'base_info',
  GENERAL_SPECIFICATION: 'general_specification',
  ANALYSIS: 'analysis',
  MICROBIOLOGICAL_TESTS: 'microbiological_tests',
  FATTY_ACID: 'fatty_acid',
  OTHER_NOTES: 'other_notes'
};

export const config = {
  // 使用枚举来获取不同类型的 data
  getTypeData(type, key, language = 'EN') {
    // 确保 key 是枚举中的一个有效值
    if (!Object.values(DataKeys).includes(key)) {
      console.error(`Invalid key: ${key}. Valid keys are:`, Object.values(DataKeys));
      return null;
    }

    // 假设 `typeData` 已经被定义并且是可访问的
    const item = typeData.find(item => item.type === type);
    if (language === 'CN') {
      item.data = typeCNData.find(item => item.type === type).data;
    }

    if (!item) {
      console.error(`No data found for type: ${type}`);
      return null;
    }

    const data = item.data[key];
    return data;
  },
  getFirstLayerAttribute(type, language) {
    let item = typeData.find(item => item.type === type);
    if (language == 'CN') {
      item = typeCNData.find(item => item.type === type);
    }
    if (!item) {
      console.error(`No data found for type: ${type}`);
      return null;
    }
    const firstLevelKeys = Object.keys(item.data);
    // console.info('firstLevelKeys:', firstLevelKeys);
    return firstLevelKeys;
  },
};