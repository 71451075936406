import * as defaultData from "../../data/DefaultData";

export const CoaType = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.CoaProductTypeData,
      value: "",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_code: {
      type: "input",
      label: "Customer Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_product_name: {
      type: "input",
      label: "Customer Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    lot: {
      type: "input",
      label: "Lot",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    po_number: {
      type: "input",
      label: "Po Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    expiration_date: {
      type: "input",
      label: "Expiration Date",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    manufacuture_date: {
      type: "input",
      label: "Manufacuture Date",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    ingredients: {
      type: "tag",
      label: "Ingredients",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    extraction_ratio: {
      type: "input",
      label: "Extraction Ratio",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "Important Constitue In Plant",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    major_constituents_in_native_herbs: {
      type: "input",
      label: "Major Constituents In Native Herbs",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    appearance: {
      type: "input",
      label: "Appearance",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    solubility: {
      type: "input",
      label: "Solubility",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    homogeneity: {
      type: "input",
      label: "Homogeneity",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    extraction_process: {
      type: "input",
      label: "Extraction Process",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    processing_aid: {
      type: "input",
      label: "Processing Aid",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    carrier: {
      type: "input",
      label: "Carrier",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    certification: {
      type: "input",
      label: "Certification",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_grade: {
      type: "input",
      label: "Product Grade",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    preservative: {
      type: "input",
      label: "Preservative",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    mesh_size: {
      type: "select",
      label: "MeshSizeOptionsData",
      tips_value: "",
      options_value: defaultData.MeshSizeOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    loss_on_drying: {
      type: "input",
      label: "Loss On Drying",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    bulk_density: {
      type: "input",
      label: "Bulk Density",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    identification: {
      type: "input",
      label: "Identification",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "input",
      label: "Total Heavy Metals",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    color: {
      type: "input",
      label: "Color",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    odor: {
      type: "input",
      label: "Odor",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    taste: {
      type: "input",
      label: "Taste",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "Total Plate Count",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "input",
      label: "Yeast And Mold",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "input",
      label: "Ecoli",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "input",
      label: "Salmonella",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "input",
      label: "Staph Aureus",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "input",
      label: "Total Coliforms",
      tips_value: { specification: "", result: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  notes: {
    note1: {
      type: "input",
      label: "Note1",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
    },
    note2: {
      type: "input",
      label: "Note2",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
    },
    note3: {
      type: "input",
      label: "Note3",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
    },
    custom: {
      type: "tag",
      label: "Custom",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    is_show_note: {
      type: "input",
      label: "Is Show Note",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
    },
  },
};
